import { IClientConfig } from '@spikemark/core';
import { ExpoClientOptions } from 'expo-server-sdk';
import { MergeDeep } from 'type-fest';

export type IServerConfig = MergeDeep<
  Omit<IClientConfig, 'baseUrl'>,
  {
    environment: string;
    mediaStorageUrl: string;
    mediaBucketName: string;
    restApiBaseUrl: string;
    version: string;
    region: string;
    sharpPath: string;
    cacheToken: string;
    cognito: {
      baseUrl: string;
      clientId: string;
      clientSecret: string;
      userPoolId: string;
    };
    launchDarkly: {
      sdkKey: string;
    };
    supabase: {
      serviceKey: string;
      jwtSecret: string;
    };
    elastic: {
      endpoint: string;
      credentials: {
        username: string;
        password: string;
      };
      logResults: boolean;
    };
    sqs: {
      partnerFeedUrl: string;
    };
    stripe: {
      secretKey: string;
      publicKey: string;
      productId: string;
      liveScoringProductId: string;
    };
    tableau: {
      baseUrl: string;
      clientId: string;
      secretId: string;
      secretValue: string;
    };
    s3: {
      rankingsReportsBucket: string;
    };
    openWeather: {
      apiKey: string;
    };
    expo: { accessToken: string };
    helpCentreUrl: string;
    googleApiKey: string;
    intercom: {
      appId: string;
    };
    taylorMadeUrl: string;
  }
>;

export const ServerConfig: IServerConfig = {
  environment: process.env.ENVIRONMENT || 'local',
  mediaStorageUrl: process.env.MEDIA_STORAGE_URL ?? '',
  mediaBucketName: process.env.MEDIA_BUCKET_NAME ?? '',
  restApiBaseUrl: process.env.REST_API_BASE_URL ?? '',
  region: process.env.AWS_REGION ?? 'us-west-2',
  webAppUrl: process.env.WEB_APP_URL ?? '',
  version: process.env.PROJ_VERSION ?? 'local',
  disableCache: process.env.DISABLE_CACHE === 'true',
  cacheRevalidate: parseInt(process.env.CACHE_REVALIDATE ?? '1800', 10),
  sharpPath: process.env.NEXT_SHARP_PATH ?? '',
  cacheToken: process.env.CACHE_TOKEN ?? '',
  cloudinary: {
    basePath: process.env.CLOUDINARY_BASE_PATH ?? '',
  },
  cognito: {
    baseUrl: process.env.AUTH_BASE_URL ?? '',
    clientId: process.env.APP_CLIENT_ID ?? '',
    clientSecret: process.env.APP_CLIENT_SECRET ?? '',
    userPoolId: process.env.APP_USER_POOL_ID ?? '',
  },
  supabase: {
    url: process.env.SUPABASE_URL ?? '',
    anonKey: process.env.SUPABASE_ANON_KEY ?? '',
    serviceKey: process.env.SUPABASE_SERVICE_KEY ?? '',
    jwtSecret: process.env.SUPABASE_JWT_SECRET ?? '',
  },
  launchDarkly: {
    sdkKey: process.env.LAUNCHDARKLY_SDK_KEY ?? '',
    clientId: process.env.LAUNCHDARKLY_CLIENT_ID ?? '',
  },
  tableau: {
    baseUrl: process.env.TABLEAU_BASE_URL ?? '',
    clientId: process.env.TABLEAU_CLIENT_ID ?? '',
    secretId: process.env.TABLEAU_SECRET_ID ?? '',
    secretValue: process.env.TABLEAU_SECRET_VALUE ?? '',
  },
  s3: {
    rankingsReportsBucket: process.env.RANKINGS_REPORTS_BUCKET ?? '',
  },
  elastic: {
    endpoint: process.env.ELASTIC_NODE_ENDPOINT as string,
    credentials: {
      username: process.env.ELASTIC_NODE_USERNAME as string,
      password: process.env.ELASTIC_NODE_PASSWORD as string,
    },
    logResults: false,
  },
  sqs: {
    partnerFeedUrl: process.env.SQS_PARTNER_FEED_URL as string,
  },
  stripe: {
    secretKey: process.env.STRIPE_SECRET_KEY ?? '',
    publicKey: process.env.STRIPE_PUBLIC_KEY ?? '',
    productId: process.env.STRIPE_PRODUCT_ID ?? '',
    liveScoringProductId: process.env.STRIPE_LIVE_SCORING_PRODUCT_ID ?? '',
  },
  mixpanel: {
    apiKey: process.env.MIXPANEL_API_KEY ?? '',
    sessionReplayPercent: parseInt(process.env.SESSION_REPLAY_PERCENT ?? '0', 10),
  },
  googleAnalytics: {
    gTag: process.env.GOOGLE_ANALYTICS_GTAG ?? '',
  },
  fetchRequestTimeout: parseInt(process.env.FETCH_REQUEST_TIMEOUT ?? '30000', 10),
  fetchRequestMaxRetries: parseInt(process.env.FETCH_REQUEST_MAX_RETRIES ?? '5', 10),
  openWeather: {
    apiKey: process.env.OPEN_WEATHER_API_KEY as string,
  },
  expo: {
    accessToken: process.env.EXPO_PUSH_ACCESS_TOKEN as string,
  },
  scoreboardLiveLearnMoreUrl: process.env.SCOREBOARD_LIVE_LEARN_MORE_URL ?? '',
  helpCentreUrl: process.env.HELP_CENTRE_URL ?? '',
  googleApiKey: process.env.GOOGLE_API_KEY ?? '',
  intercom: {
    appId: process.env.INTERCOM_APP_ID ?? '',
  },
  taylorMadeUrl: process.env.TAYLORMADE_URL ?? '',
};
